<template>
  <div class="pc-wrap memberid-signup-main mb-6">
    <v-container class="mt-1">
      <member-id-component :memberCode="memberCode" :fullName="fullName" />
      <app-recommend-component />

      <div class="pb-10 pa-3" :class="{ 'xs-wrap': !isMobile, 'mt-2': !isMobile }" v-resize="onResize">
        <v-row justify="center" class="my-5">
          <h3 class="memberid-secondary--text title font-weight-bold">ご利用開始までの流れ</h3>
        </v-row>
        <v-sheet outlined class="rounded-lg memberid-black-100 pa-6 my-2">
          <v-row justify="center">お申し込みが月〜木曜12時の場合</v-row>
          <v-row justify="center" class="mt-3 memberid-primary--text font-weight-bold">翌月曜の13:00からご注文可能</v-row>
        </v-sheet>
        <v-sheet outlined class="rounded-lg memberid-black-100 pa-6 my-2">
          <v-row justify="center">お申し込みが木曜12時〜日曜の場合</v-row>
          <v-row justify="center" class="mt-3 memberid-primary--text font-weight-bold">翌々月曜の13:00からご注文可能</v-row>
        </v-sheet>
        <v-row justify="center" class="my-3 caption">ご注文開始まで今しばらくお待ちください。</v-row>
        <v-row justify="start" class="mx-1 mt-3 pt-1 text-caption memberid-black-400--text">※システムへの反映に最大10分かかるため、アプリのログインは今から時間をおいてお試しください。</v-row>
        <v-row justify="start" class="mx-1 mt-3 pt-1 text-caption memberid-black-400--text">※お申込み内容に不備があった場合など、確認のご連絡をさせていただくことがございます。</v-row>
        <v-row justify="start" class="mx-1 mt-3 pt-1 text-caption memberid-black-400--text">※その際にお申込み手続きを保留、キャンセルさせていただく場合がございます。予めご了承ください。</v-row>
        <!--  年末対応ここから -->
        <v-sheet outlined class="rounded-0 pa-4 mt-6">
          <p class="subtitle-2">
            <span class="memberid-red--text">【年末年始の対応について】</span><br />
            年末年始はご注文・お届けの日程が通常と異なります。詳しくは<a
              target="_blank"
              rel="noopener noreferrer"
              href="https://todok.sapporo.coop/static/info/24_nenmatsu.html"
              >こちら</a
            >をご確認の上、ご注文ください。
          </p>
        </v-sheet>
        <!--  年末対応ここまで -->
        <v-row justify="center" class="mt-5 caption">例）金曜お申し込み・火曜コースになる場合</v-row>
        <v-row justify="center" class="my-3">
          <v-img :src="require('@/assets/schedule-calendar.png')" />
        </v-row>

        <v-sheet outlined class="rounded-lg pa-6 my-2">
          <v-row align="center" class="mb-1">
            <v-avatar color="memberid-secondary" size="24"><span class="white--text h4">1</span></v-avatar>
            <h4 class="memberid-secondary--text subtitle-1 font-weight-bold ml-1">スタートセットをお届け</h4>
          </v-row>
          <p class="subtitle-2">
            スタートセットと初回カタログをお届けし、配達先、置き配の場所、周辺の道路状況などを確認します。スタートセットの内容は全てお目通しをお願いします。
          </p>
          <v-layout justify-center>
            <v-img
              :src="require('@/assets/todok-start-step-1.png')"
              alt="todok-start-step-1.png"
              width="100%"
              max-width="182"
            />
          </v-layout>
        </v-sheet>
        <v-sheet outlined class="rounded-lg pa-6 my-2">
          <v-row align="center" class="mb-1">
            <v-avatar color="memberid-secondary" size="24"><span class="white--text h4">2</span></v-avatar>
            <h4 class="memberid-secondary--text subtitle-1 font-weight-bold ml-1">配達コース決定後、利用開始</h4>
          </v-row>
          <p class="subtitle-2">
            配達コースが決定したら、お電話とメールにてご連絡いたします。利用開始になりましたら、ログインして注文しましょう。
          </p>
          <v-row justify="start" class="text-caption memberid-black-400--text">※商品によっては翌週以降のお届けになることもございます。</v-row>
          <v-row justify="center">
            <v-img
              :src="require('@/assets/todok-start-step-2.png')"
              alt="todok-start-step-2.png"
              width="100%"
            />
          </v-row>
          <v-row justify="center" align="center">
            <div>
              <a href="https://apps.apple.com/jp/app/%E3%83%88%E3%83%89%E3%83%83%E3%82%AF%E3%82%A2%E3%83%97%E3%83%AA/id1473905794">
                <v-img :src="require('@/assets/badge-appstore.svg')" max-width="140" />
              </a>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/details?id=coop.sapporo.todock">
                <v-img :src="require('@/assets/badge-googleplay.png')" max-width="140" />
              </a>
            </div>
          </v-row>
        </v-sheet>
        <v-sheet outlined class="rounded-lg pa-6 my-2">
          <v-row align="center" class="mb-1">
            <v-avatar color="memberid-secondary" size="24"><span class="white--text h4">3</span></v-avatar>
            <h4 class="memberid-secondary--text subtitle-1 font-weight-bold ml-1">商品をお届け</h4>
          </v-row>
          <p class="subtitle-2">
            スタートセット内の必要書類にご記入の上、同封されている封筒にて返送、または担当者に手渡ししてください。
          </p>
          <v-layout justify-center>
            <v-img
              :src="require('@/assets/todok-start-step-3.png')"
              alt="todok-start-step-3.png"
              width="100%"
            />
          </v-layout>
          <v-row justify="end" class="mt-5">
            <v-btn text color="memberid-secondary" @click="moveLink('https://www.sapporo.coop/content/?id=2519')">よくある質問はこちら</v-btn>
          </v-row>
        </v-sheet>
      </div>
    </v-container>
    <rounded-button class="for-ga-return-todok" buttonText="トドックサイトに戻る" :nextPage="goToTodokSite" />
  </div>
</template>
<script>
// import Vue from 'vue'
import storage from '@/storage'
import AppRecommendComponent from '@/components/AppRecommendComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import MemberIdComponent from '@/components/MemberIdComponent'
import Config from '@/config'
import CommonMixin from '@/components/mixins/common-mixin'

export default {
  name: 'DeliveryMemberComplete',
  mixins: [CommonMixin],
  components: {
    AppRecommendComponent,
    RoundedButton,
    MemberIdComponent,
  },
  data() {
    return {
      memberCode: '',
      fullName: '',
    }
  },
  mounted() {
    // セッションに保存していたもの全部消す
    storage.allClear()
    this.memberCode = storage.getCompMemberCode()
    this.fullName = storage.getCompMemberName()
  },
  methods: {
    goToTodokSite() {
      window.location.href = Config.getTodokSiteEndpoint
    },
  },
  computed: {},
}
</script>
